import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/globals.css';
import './styles/index.css'
import './styles/Header.css'
import './styles/Footer.css'
import './styles/Contact.css'
import './styles/HeaderHero.css'
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
