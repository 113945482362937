import React, { useEffect } from 'react'
import { Col, Row, Typography, Divider, Button } from 'antd'
import { default as SEO } from './SEO'
import HeaderHero from './HeaderHero'
import Images from '../assets/images/Images'
// import { getThreadingTimeline } from '../services/Api'

const tabletImage = 'hero/contact/Contact_1024x300-no-copy.jpg'
const desktopImage = 'hero/contact/Contact_1440x300-no-copy.jpg'
const largeScreenImage = 'hero/contact/Contact_1620x300-no-copy.jpg'
const xtraLargeScreenImage = 'hero/contact/Contact_1960x300-no-copy.jpg'
const smallImage = 'hero/contact/Contact_375x188.jpg'
const smallImage390 = 'hero/contact/Contact_390x188.jpg'
const mobileImage = 'hero/contact/Contact_428x188.jpg'
const mobileImage768 = 'hero/contact/Contact_768x300-no-copy.jpg'

const { Link, Text } = Typography

const Home = () => {
  useEffect(() => {
    (function (g, e, n, es, ys) {
      g['_genesysJs'] = e;
      g[e] = g[e] || function () {
        (g[e].q = g[e].q || []).push(arguments)
      };
      g[e].t = 1 * new Date();
      g[e].c = es;
      ys = document.createElement('script'); ys.async = 1; ys.src = n; ys.charset = 'utf-8'; document.head.appendChild(ys);
    })(window, 'Genesys', 'https://apps.usw2.pure.cloud/genesys-bootstrap/genesys.min.js', {
      environment: 'prod-usw2',
      deploymentId: 'f04773ae-8fcd-4a1d-8075-ee45cff53d0e',
      debug: true // optional - Enables Genesys browser console logging
    });
  }, [])

  function toggleMessenger() {
    window.Genesys("command", "Messenger.open", {},
      function (o) { },  // if resolved
      function (o) {    // if rejected
        window.Genesys("command", "Messenger.close");
      }
    );
  }

  const chatNow = () => {
    toggleMessenger()
  }

  return (
    <div className="Home" style={{ height: "100%" }}>
      <SEO
        description="When support is needed, our team is here and happy to help."
        title="Contact Us | CURE Auto Insurance for NJ, PA & MI Drivers"
        url="/contact"
      />
      <HeaderHero
        desktopImage={desktopImage}
        largeScreenImage={largeScreenImage}
        mobileImage={mobileImage}
        mobileImage768={mobileImage768}
        smallImage={smallImage}
        smallImage390={smallImage390}
        tabletImage={tabletImage}
        title="CONTACT"
        xtraLargeScreenImage={xtraLargeScreenImage}
        alt={"Contact us - CURE auto insurance"}
      />
      <Row style={{ display: "flex", flexDirection: "column" }} className="contact-container page-text-margin">
        
        <Col xs={{ offset: 0 }} lg={{ offset: 3 }} xl={{ offset: 5 }}>
          <p style={{ fontSize: "18px", lineHeight: "18px", marginBottom: "16px" }}>When support is needed, our team is here and happy to help.</p>
          <Col className="margin-top-6 margin-bottom-6" span={24} style={{marginTop: "0px"}}>
            <p className="contact-sub-title" style={{ marginBottom: "10px" }}>Let's Chat!</p>
            <img style={{ cursor: "pointer" }} onClick={() => chatNow()} src={Images.chatNow}></img>
          </Col>
          <Row className="text-reset">
            <Col className="margin-bottom-8 desktop" span={24}>
              <Text className="contact-sub-title">Hours</Text>
            </Col>
          </Row>
          <Col className="margin-bottom-8 mobile" span={24}>
            <Text className="contact-sub-title">Hours</Text>
          </Col>
          <Col className="margin-bottom-12 mobile-12 mobile-sub-text" span={24}>
            <ul>
              <li style={{ fontSize: "18px" }}>Monday-Friday: 8:00am - 7:00pm</li>
              <li style={{ fontSize: "18px" }}>Saturday: 9:30am - 4:00pm</li>
            </ul>
          </Col>
        </Col>
        
      </Row>
    </div>
  )
}

Home.propTypes = {}

export default Home