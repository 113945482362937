import React, { useReducer, createContext } from 'react'
import PropTypes from 'prop-types'

export const UserContext = createContext()

const initialState = {
  emailAddress: null
}

export const reducer = (state, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export const UserContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <UserContext.Provider value={[state, dispatch]}>{props.children}</UserContext.Provider>
}

UserContextProvider.propTypes = {
  children: PropTypes.object
}
