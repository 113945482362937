import React from 'react'
import PropTypes from 'prop-types'

const CANONICAL_BASE_URL = 'https://www.cure.com'

const Seo = ({ description, title, url }) => {
    return (
        <>
            <title>{title}</title>
            <meta content={description} name="description" />
            <meta content="width=device-width, initial-scale=1.0" name="viewport" />
            <meta name="robots" content="noindex" />
            <meta name="google-site-verification" content="z6rSevZR1gRqH8wZyEipBVMIypa1qd5JpxnhJecZXRw" />
            <link data-react-helmet="true" href={`${CANONICAL_BASE_URL}${url}`} rel="canonical" />
        </>
    )
}

export default Seo

Seo.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string
}

Seo.defaultProps = {
    description: null,
    title: null,
    url: ''
}
