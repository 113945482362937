import { useState, useEffect } from 'react'

export const getDomain = type => {
    if (typeof window !== 'undefined') {
        switch (window.location.hostname) {
            case 'chatdev.cure.com':
                if (type === 'account') {
                    return 'myaccountstg.cure.com'
                } else {
                    return 'getquotestg.cure.com'
                }
            case 'localhost':
                if (type === 'account') {
                    return 'myaccountstg.cure.com'
                } else {
                    return 'getquotestg.cure.com'
                }
            case 'chatstg.cure.com':
                if (type === 'account') {
                    return 'myaccountstg.cure.com'
                } else {
                    return 'getquotestg.cure.com'
                }
            case 'chatprep.cure.com':
                if (type === 'account') {
                    return 'myaccountprep.cure.com'
                } else {
                    return 'getquoteprep.cure.com'
                }
            default:
                if (type === 'account') {
                    return 'myaccount.cure.com'
                } else {
                    return 'getquote.cure.com'
                }
        }
    }

}

export const getDomainLanding = () => {
    if (typeof window !== 'undefined') {
        switch (window.location.hostname) {
            case 'chatdev.cure.com':
                return "staging.cure.com"
            case 'localhost':
                return "staging.cure.com"
            case 'chatstg.cure.com':
                return "staging.cure.com"
            case 'chatprep.cure.com':
                return "prepublish.cure.com"
            default:
                return "cure.com"
        }
    }
}

export const getHostName = () => {
    if (typeof window !== 'undefined') {
      switch (window.location.hostname) {
        case 'chatdev.cure.com':
          return 'prod'
        case 'localhost':
          return 'prod'
        case 'chatstg.cure.com':
          return 'uat'
        case 'chatprep.cure.com':
          return 'uat'
        default:
          return 'prod'
      }
    }
  
  }

export const getTestingEnvToken = () => {
    if (typeof window !== 'undefined') {
          return "44874035-568c-4db4-aed0-69a0a3203cd6"
    }else{
      return "";
    }
  }

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined
    })

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }

        window.addEventListener('resize', handleResize)
        handleResize()
        return () => window.removeEventListener('resize', handleResize)
    }, [])
    return windowSize
}
