export const getAPIEndpoint = () => {
  if (typeof window !== 'undefined') {
    switch (window.location.hostname) {
      case 'localhost':
        return 'http://localhost:57761' //'svcstg.cure.com'
      case 'oneincstg.cure.com':
        return 'https://svcstg.cure.com'
      case 'oneincdev.cure.com':
        return 'https://svcstg.cure.com'
      case 'oneincprep.cure.com':
        return 'https://svcprep.cure.com'
      case 'oneinc.cure.com':
        return 'https://svc.cure.com'
      default:
        return 'https://svcstg.cure.com'
    }
  }
}

export const getSiteEnv = () => {
  if (typeof window !== 'undefined') {
    switch (window.location.hostname) {
      case 'localhost':
        return 'uat'
      case 'oneincdev.cure.com':
        return 'uat'
      case 'oneincprep.cure.com':
        return 'uat'
      case 'oneinc.cure.com':
        return 'prod'
      default:
        return 'uat'
    }
  }
}

export const getDomainQuote = () => {
  if (typeof window !== 'undefined') {
    switch (window.location.hostname) {
      case 'localhost':
        return 'getquotestg.cure.com'
      case 'paymentsdev.cure.com':
        return 'getquotestg.cure.com'
      case 'paymentsstg.cure.com':
        return 'getquotestg.cure.com'
      case 'paymentsprep.cure.com':
        return 'getquoteprep.cure.com'
      case 'payments.cure.com':
        return 'getquote.cure.com'
      default:
        return window.location.hostname
        break;
    }
  }
}



export const getDomain = () => {
  if (typeof window !== 'undefined') {
    switch (window.location.hostname) {
      case 'localhost':
        return 'https://staging.cure.com' //http://localhost:57761
      case 'myaccountdev.cure.com':
        return 'https://dev.cure.com'
      case 'myaccountstg.cure.com':
        return 'https://staging.cure.com'
      case 'myaccountprep.cure.com':
        return 'https://prepublish.cure.com'
      default:
        return 'https://www.cure.com'
    }
  }
}
