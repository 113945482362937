import React, { useState, lazy, Suspense } from 'react'
import { PhoneOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import { getDomain, getDomainLanding } from '../utils'

const FeedbackModal = lazy(() => import("./FeedbackModal.jsx"));

const Footers = () => {
  const [hasLoadedFeedback, setHasLoadedFeedback] = useState(false)
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)

  const handleQuoteClick = event => {
    window.open('https://' + getDomain('quote') + `/default.aspx`, '_self')
  }
  const handleMakePaymentClick = event => {
    window.open('https://' + getDomain('account') + '/oneinc.aspx', '_self')
  }
  const handleRetrieveClick = event => {
    window.open('https://' + getDomain('quote') + '/retrieve-saved-quote.aspx', '_self')
  }
  
  //Feedback modal controls
  const handleShowFeedback = event => {
    if(!hasLoadedFeedback){
      setHasLoadedFeedback(true);
    }
    setShowFeedbackModal(true);
  };
  const onCancel = () => {
    setShowFeedbackModal(false);
  };
  
  return (
    <>
      <footer>
        <Row className="desktop-footer" style={{ padding: 40, paddingBottom: 0 }}>
          <Col className="first-coloumn">
            <Row>
              <Col className="social-container-coloumn">
                <Col className="social-container" flex="100px" style={{ position: 'relative' }}>
                  <a
                    className="twitter social"
                    href="https://twitter.com/cureinsurance?lang=en"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img alt="twitter" />
                  </a>
                  <a
                    className="facebook social"
                    href="https://facebook.com/cureinsurance"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img alt="facebook" />
                  </a>
                  <a
                    className="linkedin social"
                    href="https://www.linkedin.com/company/citizens-united-reciprocal-exchange/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img alt="linkedin" />
                  </a>
                  <a
                    className="youtube social"
                    href="https://youtube.com/user/CureInsurance"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img alt="youtube" />
                  </a>
                  <a
                    className="instagram social"
                    href="https://instagram.com/cureinsurance/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img alt="instagram" />
                  </a>
                </Col>
                <Col className="margin-top-18" lg={24} xl={24}>
                  <Row className="text-reset">
                    <ul>
                      <li>
                        <a className="text-menu">
                          Report Claim
                        </a>
                      </li>
                      <li><a className='fnol-online' href={`https://${getDomainLanding()}/claims/fnol-form`}>Online</a> or 800-229-9151</li>
                    </ul>
                  </Row>
                  <Row className="text-reset">
                    <ul>
                      <li>Customer Service</li>
                      <li>800-535-2873</li>
                    </ul>
                  </Row>
                  <Row className="text-reset">
                    <ul>
                      <li>Roadside Assistance</li>
                      <li>866-522-1991</li>
                    </ul>
                  </Row>
                </Col>
              </Col>
            </Row>
          </Col>
          <Col className="second-coloumn start-quote-container">
            <Row>
              <Col className="start-quot-coloumn" lg={8} md={8} xl={8}>
                <ul>
                  <li>
                    <a
                      className="text-menu text-footer-hover"
                      onClick={handleQuoteClick}
                      rel="noopener noreferrer"
                      target="_self"
                    >
                      Start Quote
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-menu text-footer-hover"
                      onClick={handleRetrieveClick}
                      rel="noopener noreferrer"
                      target="_self"
                    >
                      Retrieve Saved Quote
                    </a>
                  </li>
                  <li>
                    <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/claims`} >
                      Claims Center
                    </a>
                  </li>
                  <li>
                    <a className="text-menu text-footer-hover"  href={`https://${getDomainLanding()}/find-repair-shops`} >
                      Repair Shops
                    </a>
                  </li>
                </ul>
              </Col>
              <Col className="log-in-coloumn" lg={8} md={8} xl={8}>
                <ul>
                  <li>
                    <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/my-account`}  rel="noopener noreferrer">
                      Log In
                    </a>
                  </li>
                  <li>
                    <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/my-account`}  rel="noopener noreferrer">
                      Get ID Card
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-menu text-footer-hover"
                      onClick={handleMakePaymentClick}
                      rel="noopener noreferrer"
                      target="_self"
                    >
                      Make Payment
                    </a>
                  </li>
                  <li>
                    <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/my-account`}  rel="noopener noreferrer">
                      Amount Due
                    </a>
                  </li>
                </ul>
              </Col>
              <Col className="why-cure-coloumn" lg={8} md={8} xl={8}>
                <ul>
                  <li>
                    <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/why-cure`} >
                      Why CURE
                    </a>
                  </li>
                  <li>
                    <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/news-media`} >
                      News/Media
                    </a>
                  </li>
                  <li>
                    <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/careers`} >
                      Careers
                    </a>
                  </li>
                  <li>
                    <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/roadside`} >
                      Roadside Assistance
                    </a>
                  </li>
                </ul>
              </Col>
              <Row>
                <Col className="address-coloumn" xl={24}>
                  <Row className="height-24" />
                  <Row align="middle" justify="center">
                    <span className="footer-title footer-address">
                      214 Carnegie Center, Suite 301, Princeton, NJ 08540
                    </span>
                  </Row>
                  <Row align="middle" className="footer-below-address-row">
                    <a className="text-menu text-underline" href={`https://${getDomainLanding()}/naic`} >
                      NAIC Numbers
                    </a>
                    <a className="text-reset text-underline" href={`https://${getDomainLanding()}/forms-and-documents`} >
                      Forms/Documents
                    </a>
                    <a className="text-reset text-underline" href={`https://${getDomainLanding()}/terms-conditions`} >
                      Terms & Conditions
                    </a>
                    <a className="text-reset text-underline" href={`https://${getDomainLanding()}/accessibility`} >
                      Accessibility
                    </a>
                    <a className="text-menu text-underline" href={`https://${getDomainLanding()}/contact`}  rel="noopener noreferrer">
                      Contact
                    </a>
                    <a className="text-reset text-underline" href={`https://${getDomainLanding()}/sitemap`}  rel="noopener noreferrer">
                      Sitemap
                    </a>
                  </Row>
                  <Row align="middle" justify="center" style={{ marginTop: 18, marginBottom: 18 }}>
                    <Button className="mobile-feedback" onClick={handleShowFeedback}>
                      SHARE FEEDBACK
                    </Button>
                  </Row>
                  <Row align="middle" justify="center">
                    <span className='copyright' style={{ marginBottom: 20 }}>
                      Copyright© {new Date().getFullYear()} CURE (Citizens United Reciprocal Exchange). All rights reserved.
                    </span>
                  </Row>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
        <Row className="medium-footer" style={{ padding: 10 }}>
          <Col xl={8} xs={24}>
            <Row>
              <Col className="text-reset" span={8}>
                <ul>
                  <li>Report Claim</li>
                  <li><a className='fnol-online' href={`https://${getDomainLanding()}/claims/fnol-form`} >Online</a> or 800-229-9151</li>
                </ul>
              </Col>
              <Col className="text-reset" span={8}>
                <ul>
                  <li>Roadside 24/7</li>
                  <li>866-522-1991</li>
                </ul>
              </Col>
              <Col className="text-reset" span={8}>
                <ul>
                  <li>Customer Service</li>
                  <li>800-535-2873</li>
                </ul>
              </Col>
            </Row>
          </Col>

          <Col xl={8} xs={24}>
            <Row>
              <Col span={8}>
                <ul>
                  <li>
                    <a
                      className="text-menu text-footer-hover"
                      onClick={handleQuoteClick}
                      rel="noopener noreferrer"
                      target="_self"
                    >
                      Start Quote
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-menu text-footer-hover"
                      onClick={handleRetrieveClick}
                      rel="noopener noreferrer"
                      target="_self"
                    >
                      Retrieve Quote
                    </a>
                  </li>
                  <li>
                    <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/claims`} >
                      Claims Center
                    </a>
                  </li>

                  <li>
                    <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/find-repair-shops`} >
                      Repair Shops
                    </a>
                  </li>
                </ul>
              </Col>
              <Col span={8}>
                <ul>
                  <li>
                    <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/my-account`}  rel="noopener noreferrer">
                      Log In
                    </a>
                  </li>
                  <li>
                    <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/my-account`}  rel="noopener noreferrer">
                      Get ID Card
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-menu text-footer-hover"
                      onClick={handleMakePaymentClick}
                      rel="noopener noreferrer"
                      target="_self"
                    >
                      Make Payment
                    </a>
                  </li>
                  <li>
                    <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/my-account`}  rel="noopener noreferrer">
                      Amount Due
                    </a>
                  </li>
                </ul>
              </Col>
              <Col span={8}>
                <ul>
                  <li>
                    <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/why-cure`} >
                      Why CURE
                    </a>
                  </li>
                  <li>
                    <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/news-media`} >
                      News/Media
                    </a>
                  </li>
                  <li>
                    <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/careers`} >
                      Careers
                    </a>
                  </li>
                  <li>
                    <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/roadside`} >
                      Roadside Assistance
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>

          <Col xl={8} xs={24}>
            <Row>
              <Col className="social-container" style={{ position: 'relative' }}>
                <a
                  className="twitter social"
                  href="https://twitter.com/cureinsurance?lang=en"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img alt="twitter" />
                </a>
                <a
                  className="facebook social"
                  href="https://facebook.com/cureinsurance"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img alt="facebook" />
                </a>
                <a
                  className="linkedin social"
                  href="https://www.linkedin.com/company/citizens-united-reciprocal-exchange/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img alt="linkedin" />
                </a>
                <a
                  className="youtube social"
                  href="https://youtube.com/user/CureInsurance"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img alt="youtube" />
                </a>
                <a
                  className="instagram social"
                  href="https://instagram.com/cureinsurance/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img alt="instagram" />
                </a>
              </Col>
            </Row>
          </Col>
          <br />
          <br />
          <Row>
            <Col xl={8} xs={24}>
              <Row style={{ marginBottom: 10 }}>
                <Col className='text-center' xl={8} xs={24}>
                  <span className="footer-title footer-address">
                    214 Carnegie Center, Suite 301, Princeton, NJ 08540
                  </span>
                </Col>
              </Row>
              <Row align="middle" justify="center" style={{ marginBottom: 12 }}>
                <a className="text-menu text-underline" href={`https://${getDomainLanding()}/naic`}  style={{ marginRight: 20 }}>
                  NAIC Numbers
                </a>
                <a />
                <a />
                <a className="text-reset text-underline" href={`https://${getDomainLanding()}/forms-and-documents`}  style={{ marginRight: 20 }}>
                  Forms/Documents
                </a>
                <a />
                <a className="text-reset text-underline" href={`https://${getDomainLanding()}/terms-conditions`}  style={{ marginRight: 20 }}>
                  Terms & Conditions
                </a>
                <a />
                <a className="text-reset text-underline" href={`https://${getDomainLanding()}/accessibility`}  style={{ marginRight: 20 }}>
                  Accessibility
                </a>
                <a
                  className="text-menu text-underline"
                  href={`https://${getDomainLanding()}/contact`} 
                  rel="noopener noreferrer"
                  style={{ marginRight: 20 }}
                >
                  Contact
                </a>
                <a
                  className="text-reset text-underline"
                  href={`https://${getDomainLanding()}/sitemap`} 
                  rel="noopener noreferrer"
                  style={{ marginRight: 20 }}
                >
                  Sitemap
                </a>
              </Row>
              <Row align="middle" justify="center" style={{ marginTop: 18, marginBottom: 18 }}>
                <Button className="mobile-feedback" onClick={handleShowFeedback}>
                  SHARE FEEDBACK
                </Button>
              </Row>
              <Row align="middle" justify="center">
                <span className='copyright' style={{ marginBottom: 0 }}>
                  Copyright© {new Date().getFullYear()} CURE (Citizens United Reciprocal Exchange).
                </span>
                <span className='copyright' style={{ marginBottom: 0 }}>
                  All rights reserved.
                </span>
              </Row>
            </Col>
          </Row>
        </Row>

        <Row className="mobile-footer">
          <Row style={{ display: 'grid', gridColumnGap: 5, textAlign: 'center' }}>
            <Col style={{ gridColumn: '1 / 2' }}>
              <Button className="footer-btn first-btn" style={{borderRadius: "0px"}}>
                <a href="tel:+18005352873">
                  <PhoneOutlined className="phone" style={{verticalAlign: "0.035em"}}/>
                  Customer Service
                </a>
              </Button>
            </Col>
            <Col style={{ gridColumn: '2 / 3' }}>
              <Button className="footer-btn-roadside footer-btn-roadside-mobile" style={{borderRadius: "0px"}}>
                <a href="tel:+18665221991">
                  <PhoneOutlined className="phone" style={{verticalAlign: "0.035em"}}/>
                  Roadside 24/7
                </a>
              </Button>
            </Col>
            <Col style={{ gridColumn: '3 / 3' }}>
              <Button className="footer-btn last-btn" style={{borderRadius: "0px"}}>
                                {/* <a href="tel:+18002299151"> */}
                <a href={`https://${getDomainLanding()}/claims/fnol-form`}>
                  {/* <PhoneOutlined className="phone" /> */}
                  Report Claim
                </a>
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xl={8} xs={0}>
              <Row>
                <Col offset={1} span={7}>
                  <ul>
                    <li>
                      <a
                        className="text-menu text-footer-hover"
                        onClick={handleQuoteClick}
                        rel="noopener noreferrer"
                        target="_self"
                      >
                        Start Quote
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-menu text-footer-hover"
                        onClick={handleRetrieveClick}
                        rel="noopener noreferrer"
                        target="_self"
                      >
                        Retrieve Quote
                      </a>
                    </li>
                    <li>
                      <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/claims`} >
                        Claims Center
                      </a>
                    </li>
                    <li>
                      <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/find-repair-shops`} >
                        Repair Shops
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col offset={1} span={7}>
                  <ul>
                    <li>
                      <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/my-account`}  rel="noopener noreferrer">
                        Log In
                      </a>
                    </li>
                    <li>
                      <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/my-account`}  rel="noopener noreferrer">
                        Get ID Card
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-menu text-footer-hover"
                        onClick={handleMakePaymentClick}
                        rel="noopener noreferrer"
                        target="_self"
                      >
                        Make Payment
                      </a>
                    </li>
                    <li>
                      <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/my-account`}  rel="noopener noreferrer">
                        Amount Due
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col offset={1} span={7}>
                  <ul>
                    <li>
                      <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/why-cure`} >
                        Why CURE
                      </a>
                    </li>
                    <li>
                      <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/news-media`} >
                        News/Media
                      </a>
                    </li>
                    <li>
                      <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/careers`} >
                        Careers
                      </a>
                    </li>
                    <li>
                      <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/roadside`} >
                        Roadside
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col xl={0} xs={24}>
              <Row style={{ justifyContent: 'center' }}>
                <Col className="first-coloumn-below-buttons" style={{ marginRight: 58 }}>
                  <ul>
                    <li>
                      <a
                        className="text-menu text-footer-hover"
                        onClick={handleQuoteClick}
                        rel="noopener noreferrer"
                        target="_self"
                      >
                        Start Quote
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-menu text-footer-hover"
                        onClick={handleRetrieveClick}
                        rel="noopener noreferrer"
                        target="_self"
                      >
                        Retrieve Quote
                      </a>
                    </li>
                    <li>
                      <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/claims`} >
                        Claims Center
                      </a>
                    </li>
                    <li>
                      <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/find-repair-shops`} >
                        Repair Shops
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col className="second-coloumn-below-buttons" style={{ marginRight: 58 }}>
                  <ul>
                    <li>
                      <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/my-account`}  rel="noopener noreferrer">
                        Log In
                      </a>
                    </li>
                    <li>
                      <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/my-account`}  rel="noopener noreferrer">
                        Get ID Card
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-menu text-footer-hover"
                        onClick={handleMakePaymentClick}
                        rel="noopener noreferrer"
                        target="_self"
                      >
                        Make Payment
                      </a>
                    </li>
                    <li>
                      <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/my-account`}  rel="noopener noreferrer">
                        Amount Due
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col className="third-coloumn-below-buttons">
                  <ul>
                    <li>
                      <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/why-cure`} >
                        Why CURE
                      </a>
                    </li>
                    <li>
                      <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/news-media`} >
                        News/Media
                      </a>
                    </li>
                    <li>
                      <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/careers`} >
                        Careers
                      </a>
                    </li>
                    <li>
                      <a className="text-menu text-footer-hover" href={`https://${getDomainLanding()}/roadside`} >
                        Roadside
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xl={8} xs={24}>
              <Row
                style={{
                  justifyContent: 'center',
                  display: 'block',
                  textAlign: 'center'
                }}
              >
                <Col
                  className="social-container"
                  style={{
                    position: 'relative',
                    justifyContent: 'center',
                    display: 'flex',
                    marginLeft: 0
                  }}
                >
                  <a
                    className="twitter social"
                    href="https://twitter.com/cureinsurance?lang=en"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img alt="twitter" />
                  </a>
                  <a
                    className="facebook social"
                    href="https://facebook.com/cureinsurance"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img alt="facebook" />
                  </a>
                  <a
                    className="linkedin social"
                    href="https://www.linkedin.com/company/citizens-united-reciprocal-exchange/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img alt="linkedin" />
                  </a>
                  <a
                    className="youtube social"
                    href="https://youtube.com/user/CureInsurance"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img alt="youtube" />
                  </a>
                  <a
                    className="instagram social"
                    href="https://instagram.com/cureinsurance/"
                    rel="noopener noreferrer"
                    style={{ marginRight: 0 }}
                    target="_blank"
                  >
                    <img alt="instagram" />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <br />

          <Row style={{ justifyContent: 'center' }}>
            <Col xl={8} xs={0}>
              <Row>
                <Col className='text-center' xl={8} xs={24}>
                  <span className="footer-title footer-address">
                    214 Carnegie Center, Suite 301, Princeton, NJ 08540
                  </span>
                </Col>
              </Row>
              <Row align="middle" justify="center" style={{ marginBottom: 12 }}>
                <a className="text-menu text-underline" href={`https://${getDomainLanding()}/naic`}  style={{ marginRight: 20 }}>
                  NAIC Numbers
                </a>
                <a />
                <a className="text-reset text-underline" href={`https://${getDomainLanding()}/forms-and-documents`}  style={{ marginRight: 20 }}>
                  Forms/Documents
                </a>
                <a />
                <a className="text-reset text-underline" href={`https://${getDomainLanding()}/terms-conditions`}  style={{ marginRight: 20 }}>
                  Terms & Conditions
                </a>
                <a />
                <a className="text-reset text-underline" href={`https://${getDomainLanding()}/accessibility`}  style={{ marginRight: 20 }}>
                  Accessibility
                </a>
                <a
                  className="text-menu text-underline"
                  href={`https://${getDomainLanding()}/contact`} 
                  rel="noopener noreferrer"
                  style={{ marginRight: 20 }}
                >
                  Contact
                </a>
                <a
                  className="text-reset text-underline"
                  href={`https://${getDomainLanding()}/sitemap`} 
                  rel="noopener noreferrer"
                  style={{ marginRight: 20 }}
                >
                  Sitemap
                </a>
              </Row>
              <Row align="middle" justify="center">
                <span className='copyright'>
                  Copyright© {new Date().getFullYear()} CURE (Citizens United Reciprocal Exchange). All rights reserved.
                </span>
              </Row>
            </Col>
            <Row>
              <Col xl={0} xs={24}>
                <Row>
                  <Col className='text-center' xl={8} xs={24}>
                    <span className="footer-title footer-address">
                      214 Carnegie Center, Suite 301, Princeton, NJ 08540
                    </span>
                  </Col>
                </Row>
                <Row align="middle" justify="center" style={{ display: 'grid', gridColumnGap: 12 }}>
                  <a
                    className="text-menu text-underline footer-mobile-margin-right"
                    href={`https://${getDomainLanding()}/naic`} 
                    style={{ marginRight: '12px!important', gridColumn: '1' }}
                  >
                    NAIC Numbers
                  </a>
                  <a
                    className="text-reset text-underline footer-mobile-margin-right"
                    href={`https://${getDomainLanding()}/forms-and-documents`} 
                    style={{ marginRight: '12px!important', gridColumn: '2' }}
                  >
                    Forms/Documents
                  </a>
                  <a
                    className="text-reset text-underline footer-mobile-margin-right"
                    href={`https://${getDomainLanding()}/terms-conditions`} 
                    style={{ marginRight: '12px!important', gridColumn: '3' }}
                  >
                    Terms & Conditions
                  </a>
                </Row>
                <Row
                  align="middle"
                  justify="center"
                  style={{
                    display: 'grid',
                    gridColumnGap: 12,
                    marginBottom: 12
                  }}
                >
                  <a
                    className="text-reset text-underline footer-mobile-margin-right"
                    href={`https://${getDomainLanding()}/accessibility`} 
                    style={{ marginRight: '12px!important', gridColumn: '1' }}
                  >
                    Accessibility
                  </a>
                  <a
                    className="text-menu text-underline footer-mobile-margin-right"
                    href={`https://${getDomainLanding()}/contact`} 
                    rel="noopener noreferrer"
                    style={{ marginRight: '12px!important', gridColumn: '2' }}
                  >
                    Contact
                  </a>
                  <a
                    className="text-reset text-underline footer-mobile-margin-right"
                    href={`https://${getDomainLanding()}/sitemap`} 
                    rel="noopener noreferrer"
                    style={{ marginRight: 12, gridColumn: '3' }}
                  >
                    Sitemap
                  </a>
                </Row>
                <Row align="middle" justify="center" style={{ marginTop: 18, marginBottom: 18 }}>
                  <Button className="mobile-feedback" onClick={handleShowFeedback}>
                    SHARE FEEDBACK
                  </Button>
                </Row>
                <Row align="middle" justify="center">
                  <span className='copyright' style={{ marginBottom: 0 }}>
                    Copyright© {new Date().getFullYear()} CURE (Citizens United Reciprocal Exchange).
                  </span>
                  <span className='copyright' style={{ marginBottom: 0 }}>
                    All rights reserved.
                  </span>
                </Row>
              </Col>
            </Row>
          </Row>
        </Row>
      </footer>
      <Suspense fallback={<div></div>}>
        {
          hasLoadedFeedback ? 
          <FeedbackModal 
          open = {showFeedbackModal}
          onCancel = {() => onCancel()}
          /> : 
          null
        }
      </Suspense>
    </>
  )
}

Footers.propTypes = {}

export default Footers
