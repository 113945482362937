export default {
    cureLogo: require("./CURE_Logo.png"),
    chatNow: require("./CHAT NOW Button.png"),
    michigan: require("./mi.png"),
    underline: require("./mi_underline.png"),
    halfRoundy: require("./roundy_up2.png"),
    roundyIcon: require("./icon-roundy.png"),
    happyBirthday: require("./happyBirthday.png"),
    statement: require("./icon-statement.png"),
    portalOne: {
        cureLogo: require("./c-logo.gif"),
        nouns: {
            envelope: require("./noun-envelope.png"),
            infinity: require("./noun-infinity.png"),
            mobile: require("./noun-mobile.png"),
            phonePayment: require("./noun-phone-payment.png"),
            telemarketing: require("./noun-telemarketing.png")
        },
        paymentMethod: {
            Undefined: "../images/bank-icon.png",
            Visa: "https://portalone.processonepayments.com/GenericModalV2/assets/images/card-logos/icon-accounts-visa-50x32.svg",
            MasterCard: "https://portalone.processonepayments.com/GenericModalV2/assets/images/card-logos/icon-accounts-master-50x32.svg",
            AmericanExpress: "https://portalone.processonepayments.com/GenericModalV2/assets/images/card-logos/icon-accounts-amex-50x32.svg",
            Discover: "https://portalone.processonepayments.com/GenericModalV2/assets/images/card-logos/icon-accounts-dscvr-50x32.svg",
            Eft: "https://portalone.processonepayments.com/GenericModalV2/assets/icon-accounts-bank-50x32.dcb0f6ddf79779b5ea60.svg",
            Other: "../images/card-icon.png"
        },
        paymentMethodAlt: {
            Eft: "https://www.flaticon.com/free-icons/bank",
            Other: "https://www.flaticon.com/free-icons/credit-card"
        }
    }

}