import React, { useEffect, useState, lazy, Suspense } from 'react'
import { CreditCardOutlined, InfoCircleOutlined, MenuOutlined, PhoneOutlined } from '@ant-design/icons'
import { Button, Col, Layout, Menu, Row, Typography, Skeleton } from 'antd'
import PropTypes from 'prop-types'
import { getDomain } from '../utils'
import { useWindowSize } from '../../src/utils'
import { getWebsiteMaintenanceMessage, getWebsiteMaintenanceStatus } from '../services/Api'
import '../styles/Header.css'
import { getDomainLanding } from '../utils'

const FeedbackModal = lazy(() => import("./FeedbackModal"));
const DrawerMenu = lazy(() => import("./DrawerMenu"));

const { Link } = Typography

const Header = (props) => {
  const [hasLoadedDrawer, setHasLoadedDrawer] = useState(false)
  const [showDrawer, setShowDrawer] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState()
  
  const [hasLoadedFeedback, setHasLoadedFeedback] = useState(false)
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)

  const [websiteMaintenanceMessage, setWebsiteMaintenanceMessage] = useState(null)
  const [websiteMaintenanceStatus, setWebsiteMaintenanceStatus] = useState(false)
  const [isMaintainenceLoading, setIsMaintainenceLoading] = useState(false)

  useEffect(() => {
    async function fetchWebsiteMaintainence() {
      const websiteStatusResponse = await getWebsiteMaintenanceStatus()
      const websiteMessageResponse = await getWebsiteMaintenanceMessage()
      if (websiteStatusResponse && websiteStatusResponse.data) {
        const status = websiteStatusResponse.data
        if (window.location.pathname.includes('my-account')) {
          if (status.Result == "Active" && status?.Pages.includes("MyAccountLogin")) {
            setWebsiteMaintenanceStatus(true)
          }
        }
        else {
          if (status.Result == "Active" && status?.Pages.includes("CurePages")) {
            setWebsiteMaintenanceStatus(true)
          }
        }
      }
      if (websiteMessageResponse && websiteMessageResponse.data) {
        setWebsiteMaintenanceMessage(websiteMessageResponse.data.Result)
      }
      setIsMaintainenceLoading(true)
      setIsLoading(false)
    }

    fetchWebsiteMaintainence()
  }, [])

  //Drawer controls
  const handleShowDrawer = event => {
    if(!hasLoadedDrawer){
      setHasLoadedDrawer(true);
    }
    setShowDrawer(true);
  };
  const onCloseDrawer = () => {
    setShowDrawer(false)
  }
  const handleMenuClick = e => {
    setSelectedMenu(e.key)
    setShowDrawer(false)
  }

  //Feedback modal controls
  const handleShowFeedback = event => {
    if(!hasLoadedFeedback){
      setHasLoadedFeedback(true);
    }
    setShowFeedbackModal(true);
  };
  const onCloseFeedback = () => {
    setShowFeedbackModal(false);
  };

  const [isLoading, setIsLoading] = useState(true);
  const windowSize = useWindowSize()

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: (windowSize.width < 1026 ? 112 : 64), backgroundColor: '#376998' }}>
        <Skeleton.Button 
          active
          style={{ width: 200, backgroundColor: 'rgb(59 111 159)' }}
          shape='round'
        />
      </div>
    )
  }

  return (
    <>
      {isMaintainenceLoading && (
        <>
          {websiteMaintenanceStatus && (
            <Layout.Header className="alert-nav">
              <Row>
                <Col className="margin-auto">
                  <section className="alert-message">
                    <div className="image-icon">
                      <InfoCircleOutlined />
                    </div>
                    <p className="title">{websiteMaintenanceMessage}</p>
                  </section>
                </Col>
                <Button className="feedback" onClick={handleShowFeedback}>
                  SHARE FEEDBACK
                </Button>
              </Row>
            </Layout.Header>
          )}
          {/* {!websiteMaintenanceStatus && (
            <Layout.Header className="alert-nav">
              <Row>
                <Col className="margin-auto">
                  <a href={'https://' + getDomain('quote') + '/default.aspx'} rel="noreferrer" target="_self">
                    <img alt="mi auto insurance CURE" className="logo" src="https://www.cure.com/images/mi.png" />
                    <Title className="alert-title" level={3}>
                      GREAT LAKES, GREAT INSURANCE - NOW IN MICHIGAN!
                    </Title>
                    <img alt="mi CURE" className="alert-nav-img" src="https://www.cure.com/images/mi_underline.png" />
                  </a>
                </Col>
                <Button className="feedback" onClick={() => setShowFeedbackModal(true)}>
                  SHARE FEEDBACK
                </Button>
              </Row>
            </Layout.Header>
          )} */}
          <Layout.Header className="alert-nav">
            <Row style={{height: "64px", lineHeight: "64px"}}>
              <Col style={{margin: "auto",height: "64px", lineHeight: "64px"}}>
                <a style={{color: "#fff", display: "flex"}} onClick={() => window.open(`https://${window.location.hostname === "chatstg.cure.com" ? "staging" : window.location.hostname === "chatprep.cure.com" ? "prepublish" : ""}.cure.com/download-mobile-app`, '_self', 'noopener,noreferrer')}>
                  <img alt="mi auto insurance CURE" className="logo" src="https://staging.cure.com/images/bannerPhoneIcon.png" style={{ alignSelf: 'center', width: '30px' }} />
                  <span className='alert-title' style={{ marginLeft: '15px', fontSize: '22px' }}>
                    CURE App. Everything at your fingertips. Download Now.
                  </span>
                  <img alt="mi CURE" className="alert-nav-img" src="https://assets.cure.com/img/main-site/webp/mi_underline.webp" />
                </a>
              </Col>
            </Row>
          </Layout.Header>
          <Layout.Header className="menu-nav">
            <Row>
              <Col lg={4} md={5} sm={2} xl={3} xs={2}>
                <div className="logo-holder">
                  <a href={`https://${getDomainLanding()}/`}>
                    <img alt="CURE auto insurance logo" src="https://assets.cure.com/img/main-site/cure-logo-resized.webp" />
                  </a>
                </div>
              </Col>
              <Col lg={8} md={5} sm={2} xl={10} xs={2}>
                <Menu className="menu-container" mode="horizontal">
                  <Menu.Item key="1">
                    <a href={`https://${getDomainLanding()}/claims`}>CLAIMS</a>
                  </Menu.Item>
                  <Menu.Item key="roadsideassistance" className="roadside-assistance-menu">
                    <a href={`https://${getDomainLanding()}/roadside`}>ROADSIDE</a>
                  </Menu.Item>
                  <Menu.Item key="7">
                    <a
                      href={'https://' + getDomain('account') + '/oneinc.aspx'}
                      rel="noopener noreferrer"
                      target="_self"
                    >
                      PAY ONLINE
                    </a>
                  </Menu.Item>
                </Menu>
              </Col>
              <Col lg={12} md={5} sm={2} xl={11} xs={2}>
                <div className="expanol-link-container" style={{height: "100%", display: "flex", alignItems: "center"}}>
                  <Link
                    href="tel:+18005352873"
                    style={{
                      marginRight: 20,
                      color: '#fff',
                      verticalAlign: 'middle'
                    }}
                  >
                    <PhoneOutlined className="phone" style={{transform: "translateY(-3px) rotate(120deg)"}}/>
                    800-535-CURE (2873)
                  </Link>
                  <Button
                    className="start-quote"
                    href={'https://' + getDomain('quote') + '/default.aspx'}
                    rel="noopener noreferrer"
                    style={{
                      marginRight: 20,
                      background: '#ff8000',
                      border: 'none',
                      color: '#fff',
                      textDecoration: "none",
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "3px"
                    }}
                    target="_self"
                  >
                    <span style={{width: "100%", height: "100%", transform: "translateY(-3px)", fontWeight: "normal"}}>START QUOTE</span>
                  </Button>
                  <Button
                    className="sign-in"
                    href={`https://${getDomainLanding()}/my-account`}
                    rel="noopener noreferrer"
                    style={{
                      marginRight: 20,
                      background: '#ff8000',
                      border: 'none',
                      color: '#fff',
                      textDecoration: "none",
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "3px"
                    }}
                  >
                    <span style={{width: "100%", height: "100%", transform: "translateY(-3px)", fontWeight: "normal"}}>LOG IN</span>
                  </Button>
                </div>
              </Col>
            </Row>
          </Layout.Header>
          <Layout.Header className="menu-nav-mobile">
            <Row>
              <Col lg={4} md={5} sm={5} xl={3} xs={10}>
                <div className="logo-holder">
                  <a href={`https://${getDomainLanding()}/`}>
                    <img alt="CURE auto insurance logo" src="https://assets.cure.com/img/main-site/cure-logo-resized.webp" />
                  </a>
                </div>
              </Col>
              <Col className="space-column" lg={8} md={8} sm={9} xl={10} xs={9} />
              <Col lg={12} md={11} sm={10} xl={10} xs={14}>
                <div className="expanol-link-container" style={{height: "100%", display: "flex", alignItems: "center"}}>
                  <Button
                    className="start-quote"
                    href={'https://' + getDomain('quote') + '/default.aspx'}
                    rel="noopener noreferrer"
                    target="_self"
                    style={{
                      marginRight: 20,
                      background: '#ff8000',
                      border: 'none',
                      color: '#fff',
                      textDecoration: "none",
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "3px"
                    }}
                  >
                    <span style={{width: "100%", height: "100%", transform: "translateY(-3px)", fontWeight: "normal"}}>START QUOTE</span>
                  </Button>
                  <Button className="menu-icon" icon={<MenuOutlined />} onClick={handleShowDrawer} size="large" />
                </div>
                <Suspense fallback={<div></div>}>
                  {
                    hasLoadedDrawer ? 
                    <DrawerMenu 
                      open = {showDrawer}
                      onCancel = {() => onCloseDrawer()}
                      handleMenuClick = {() => handleMenuClick()}
                      selectedMenu = {selectedMenu}
                    /> : 
                    null
                  }
                </Suspense>
              </Col>
            </Row>
          </Layout.Header>
          <Layout.Header className="secondary-nav" style={{fontWeight: "bold"}}>
            <Row style={{ display: 'grid', gridColumnGap: 18 }}>
              <Col style={{ gridColumn: '1' }}>
                <Link className="link-text" href="tel:+18005352873">
                  <PhoneOutlined className="phone" style={{verticalAlign: "0.035em"}}/>
                  <span>Contact Us</span>
                </Link>
              </Col>
              <Col className="center" style={{ gridColumn: '2' }}>
                <Link className="link-text" href="tel:+18665221991">
                  <PhoneOutlined className="phone" style={{verticalAlign: "0.035em"}}/>
                  <span className="rsa-mobile-text">Roadside 24/7</span>
                </Link>
              </Col>
              <Col className="right" style={{ gridColumn: '3' }}>
                <Link
                  className="link-text"
                  href={'https://' + getDomain('account') + '/oneinc.aspx'}
                  rel="noopener noreferrer"
                  style={{ marginLeft: 6 }}
                  target="_self"
                >
                  <CreditCardOutlined className="credit-card" style={{verticalAlign: "0.035em"}}/>
                  Pay Online
                </Link>
              </Col>
            </Row>
          </Layout.Header>
          <Suspense fallback={<div></div>}>
            {
              hasLoadedFeedback ? 
              <FeedbackModal 
              open = {showFeedbackModal}
              onCancel = {() => onCloseFeedback()}
              /> : 
              null
            }
          </Suspense>
        </>
      )}
    </>
  )
}

Header.propTypes = {
  hideGlobalHeader: PropTypes.bool
}

Header.defaultProps = {
  hideGlobalHeader: true
}

export default Header
