import React from 'react'
import Header from './Header'
import Footer from './Footer'

const Layout = ({ children }) => {
    const layoutStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    }
    return (
        <div className="cure-main-container" style={layoutStyle}>
            <Header />
            {children}
            <Footer />
        </div>
    )
}

export default Layout