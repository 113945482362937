import 'antd/dist/antd'
import { BrowserRouter, Navigate, Route, Routes, } from "react-router-dom";
import { UserContextProvider } from "./context";
import Layout from "./components/Layout";
import Home from "./components/Home";
import './styles/globals.css';
import './styles/index.css'
import './styles/Header.css'
import './styles/Footer.css'
import './styles/Contact.css'
import './styles/HeaderHero.css'
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { getTestingEnvToken, getHostName } from './utils';

function App() {
  useEffect(() => {
    if(Cookies.get('CTLE', { domain: '.cure.com' }) != getTestingEnvToken() && getHostName() != 'prod'){
      window.location = "https://www.cure.com";
    }
  }, [])

  return (
    <BrowserRouter>
      <UserContextProvider>
        <Layout>
          <Routes>
            <Route index element={<Home />} />
          </Routes>
        </Layout>
      </UserContextProvider>
    </BrowserRouter >
  );
}

export default App;
