import React from 'react'
import { Col, Row, Typography } from 'antd'
import PropTypes from 'prop-types'

const { Title } = Typography

const HeaderHero = ({
  desktopImage,
  largeScreenImage,
  mobileImage,
  mobileImage768,
  smallImage,
  smallImage390,
  tabletImage,
  title,
  xtraLargeScreenImage,
  alt
}) => {
  return (
    <header className="header-hero">
      <div className="bg-container">
        <picture style={{height: "100%"}} >
          <source
            media="(min-width: 1900px)"
            srcSet={`https://assets.cure.com/img/main-site/${xtraLargeScreenImage}`}
          />
          <source media="(min-width: 1336px)" srcSet={`https://assets.cure.com/img/main-site/${largeScreenImage}`} />
          <source media="(min-width: 1030px)" srcSet={`https://assets.cure.com/img/main-site/${desktopImage}`} />
          <source media="(min-width: 770px)" srcSet={`https://assets.cure.com/img/main-site/${tabletImage}`} />
          <source media="(min-width: 820px)" srcSet={`https://assets.cure.com/img/main-site/${mobileImage768}`} />
          <source media="(min-width: 428px)" srcSet={`https://assets.cure.com/img/main-site/${mobileImage}`} />
          <source media="(min-width: 415px)" srcSet={`https://assets.cure.com/img/main-site/${smallImage390}`} />
          <source media="(min-width: 390px)" srcSet={`https://assets.cure.com/img/main-site/${smallImage390}`} />
          <source media="(min-width: 376px)" srcSet={`https://assets.cure.com/img/main-site/${smallImage}`} />
          <img alt={alt||"header-hero"} className="full-image" src={`https://assets.cure.com/img/main-site/${smallImage}`} />
        </picture>
      </div>
      <Row className='text-inner-wrapper'>
        <Col xs={{ offset: 0 }} lg={{ offset: 3 }} xl={{ offset: 5 }}>
          <Title className="title" id="headerhero-title" level={2}>
            {title}
          </Title>
        </Col>
      </Row>
    </header>
  )
}

HeaderHero.propTypes = {
  desktopImage: PropTypes.string,
  image: PropTypes.string,
  largeScreenImage: PropTypes.string,
  mobileImage: PropTypes.string,
  mobileImage768: PropTypes.string,
  smallImage: PropTypes.string,
  smallImage390: PropTypes.string,
  tabletImage: PropTypes.string,
  title: PropTypes.string,
  xtraLargeScreenImage: PropTypes.string
}

export default HeaderHero
