import { getAPIEndpoint } from "./Utils"
const REACT_SP_API_URL = `${getAPIEndpoint()}/api/service-portal`
const REACT_PORTAL_API_URL = (env) => `https://${env == "prod" ? "portalone" : "stgportalone"}.processonepayments.com`
const REACT_MA_API_URL = `${getAPIEndpoint()}/api/my-account`
const REACT_APP_API_URL = `${getAPIEndpoint()}/api/app`
const REACT_ONEINC_URL = `${getAPIEndpoint()}/api/main`
const GENESYS_API_URL = `${getAPIEndpoint()}/api/webmessaging`
const REACT_APP_GW_API = 'https://cure-uat.mu-1-us-west-2.guidewire.net/coreapi/v5'
// const REACT_APP_GW_API = 'https://cure-prod.xi-1-us-west-2.guidewire.net/coreapi/v5'
const PORTAL_ONE_API_URL =
  "https://stgportalone.processonepayments.com/Api/Api";


export const genesysEndpoint = {
  getThreadingTimeline: {
    method: "POST",
    url: `${GENESYS_API_URL}/GetThreadingTimeline`
  }
}

export const oneincEndpoint = {
  loadPaymentFromToken: {
    method: "POST",
    url: `${REACT_ONEINC_URL}/LoadPaymentFromToken`
  },
  getPaymentInfo: {
    method: "POST",
    url: `${REACT_ONEINC_URL}/GetPaymentInfo`
  },
  getQuotePaymentInfo: {
    method: "POST",
    url: `${REACT_ONEINC_URL}/GetQuotePaymentInfo`
  },
  retrieveOneIncSessionID: {
    method: "POST",
    url: `${REACT_ONEINC_URL}/RetrieveOneIncSessionID`
  },
  enrollAutoPay: {
    method: "POST",
    url: `${REACT_ONEINC_URL}/EnrollAutoPay`
  },
  updateAutoPay: {
    method: "POST",
    url: `${REACT_ONEINC_URL}/UpdateAutoPay`
  },
  cancelAutoPay: {
    method: "POST",
    url: `${REACT_ONEINC_URL}/CancelAutoPay`
  },
  logDownPayment: {
    method: "POST",
    url: `${REACT_ONEINC_URL}/LogDownPayment`
  }
}

export const websiteMaintenanceEndpoint = {
  status: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/main/CheckWebsiteMaintenance`
  },
  message: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/main/GetWebsiteMaintenanceMessage`
  }
}

export const driverInfoEndpoint = {
  getAddress: {
    method: "POST",
    url: `${REACT_SP_API_URL}/GetAddress`,
  },

  getDrivers: {
    method: "POST",
    url: `${REACT_SP_API_URL}/GetDrivers`,
  },

  getVehicles: {
    method: "POST",
    url: `${REACT_SP_API_URL}/GetVehicles`,
  },

  getEffectiveDate: {
    method: "POST",
    url: `${REACT_SP_API_URL}/GetEffectiveDate`,
  },

  getEffectiveDate: {
    method: 'POST',
    url: `${REACT_SP_API_URL}/GetEffectiveDate`
  },

  validateAddress: {
    method: "POST",
    url: `${REACT_MA_API_URL}/ValidateAddress`,
  },

  getZip: {
    method: "POST",
    url: `${REACT_MA_API_URL}/GetZip`
  }
};

export const addressChangeEndpoint = {
  pushMain: {
    method: "POST",
    url: `${REACT_SP_API_URL}/HandleChangeRequestMain`,
  },

  pushVehicle: {
    method: "POST",
    url: `${REACT_SP_API_URL}/HandleChangeRequestVehicle`,
  },

  pushPerson: {
    method: "POST",
    url: `${REACT_SP_API_URL}/HandleChangeRequestPerson`,
  },

  getPendingPolicyChanges: {
    method: "POST",
    url: `${REACT_SP_API_URL}/GetPendingPolicyChanges`
  }
};

export const policyChangeEndpoint = {
  checkCodes: {
    method: "POST",
    url: `${REACT_SP_API_URL}/HandleChangeRequestCheckCodes`,
  },

  twilioRegistration: {
    method: "POST",
    url: `${REACT_APP_API_URL}/TwilioRegistration`,
  },
};

export const vehicleChangeEndpoint = {

  getLeasingList: {
    method: 'POST',
    url: `${REACT_SP_API_URL}/getLeasingList`
  },

  getFinanceCompanyList: {
    method: 'POST',
    url: `${REACT_SP_API_URL}/getFinanceCompanyList`
  },

  vinLookup: {
    method: 'POST',
    url: `${REACT_SP_API_URL}/VinLookup`
  }
}


export const loginEndpoint = {
  feedback: {
    method: 'POST',
    url: `${getAPIEndpoint()}/api/main/ProcessFeedback`
  },
  verify: {
    method: "POST",
    url: `${REACT_MA_API_URL}/VerifyLoginGW`,
  },

  verifyUser: {
    method: "POST",
    url: `${REACT_MA_API_URL}/VerifyLogin`,
  },

  verifyQS: {
    method: "POST",
    url: `${REACT_MA_API_URL}/LoadByPhoneAndZip`,
  },

  externalLogin: {
    method: "POST",
    url: `${REACT_MA_API_URL}/LoadByExternalToken`,
  },

  registerAccount: {
    method: "POST",
    url: `${REACT_MA_API_URL}/RegisterAccount`,
  },

  retrievePolicyUser: {
    method: "POST",
    url: `${REACT_MA_API_URL}/RetrievePolicyUser`,
  },

  resetPassword: {
    method: "POST",
    url: `${REACT_MA_API_URL}/SendTempPassword`,
  },

  getSecurityQuestions: {
    method: "POST",
    url: `${REACT_MA_API_URL}/GetSecurityQuestions`,
  },

  checkSecurityQuestions: {
    method: "POST",
    // url: `http://localhost:57761/api/my-account/CheckSecurityQuestions`
    url: `${REACT_MA_API_URL}/CheckSecurityQuestions`,
  },

  changeSecurityQuestions: {
    method: "POST",
    url: `${REACT_MA_API_URL}/ChangeSecurityQuestions`,
  },

  updateEmail: {
    method: "POST",
    url: `${REACT_MA_API_URL}/UpdateEmail`,
  },

  updateUsername: {
    method: "POST",
    url: `${REACT_MA_API_URL}/UpdateUsername`,
  },

  updatePassword: {
    method: "POST",
    url: `${REACT_MA_API_URL}/UpdatePassword`,
  },

  authenticateODS: {
    method: "POST",
    url: `${REACT_MA_API_URL}/AuthenticateODS`,
  },

  setPaperlessEmail: {
    method: "POST",
    url: `${REACT_MA_API_URL}/SetPaperlessEmail`,
  },

  loadByPolicyNumber: {
    method: "POST",
    url: `${REACT_MA_API_URL}/LoadByPolicyNumber`,
  },
};

export const portalOne = {
  portalOneAuthKey: (env) =>
    env == "prod"
      ? "F605DF6A-5707-4A00-8A1E-0FE743EAD76B"
      : "D2D344B8-8F34-4F95-9A81-976E8195BBFC",
  authKey: (env) =>
    env == "prod"
      ? "f8e7ef9c-e029-44f9-9e29-41e83cc50a7e"
      : "b0cffc04-1846-4ddb-bb60-46d6a0bd53a9",

  getCustomerId: {
    method: "POST",
    url: (env) => `${REACT_PORTAL_API_URL(env)}/Api/Api/Customer/CreateAccount`,
  },

  getPaymentMethod: {
    method: "POST",
    url: (env) => `${REACT_PORTAL_API_URL(env)}/Api/Api/PaymentMethod/Get`,
  },

  enrollAutoPay: {
    method: "POST",
    url: (env) =>
      `${REACT_PORTAL_API_URL(env)}/InstallmentsOne/api/InstallmentPlan/Enroll`,
  },

  checkAutoPay: {
    method: "POST",
    url: (env) =>
      `${REACT_PORTAL_API_URL(env)}/InstallmentsOne/api/InstallmentPlan/GetPaymentMethodInstallmentsInfoByToken`,
  },

  getActiveInstallmentPlan: {
    method: "POST",
    url: (env) =>
      `${REACT_PORTAL_API_URL(env)}/InstallmentsOne/api/InstallmentPlan/GetActiveInstallmentPlanByPolicyRefNumber`,
  },

  // checkPBTStatus: {
  //   method: "POST",
  //   url: (env) =>
  //     `${REACT_PORTAL_API_URL(env)}/Communication/api/Subscriptions/GetSubscriptionDetails`,
  // },

  checkPBTStatus: {
    method: "POST",
    url: `${REACT_MA_API_URL}/CheckPBTStatus`,
  },
};

export const paymentEndpoint = {
  getPaymentInfo: {
    method: 'POST',
    url: `${REACT_MA_API_URL}/GetPaymentInfo`,
    // query: 'PaymentCenter.sql',
  },

  getLastPayment: {
    method: "POST",
    url: `${REACT_APP_API_URL}/RunQuery`,
    query: "PaymentCenterLast.sql",
  },

  getPaymentHistory: {
    method: 'POST',
    url: `${REACT_MA_API_URL}/GetPaymentHistory`,
  },

  getPaperlessBillHistory: {
    method: "POST",
    url: `${REACT_APP_API_URL}/RunQuery`,
    query: "GetPaperlessBillHistory.sql",
  },

  getInstallmentBill: {
    method: "POST",
    url: `${REACT_MA_API_URL}/GetInstallmentBill`,
  },

  registerForBilling: {
    method: "POST",
    url: `${REACT_MA_API_URL}/RegisterForBilling`,
  },

  getPaymentPdf: {
    method: "POST",
    url: `${REACT_APP_API_URL}/GetPdf`,
    endpoint: `https://{{GW_DOMAIN}}.guidewire.net/coreapi/v5/policies/{{systemId}}/documents/{{id}}/content`,
  },

  getAllPolicyDocs: {
    method: "POST",
    url: `${REACT_APP_API_URL}/RunQuery`,
    query: "GetAllPolicyDocs.sql",
  },

  getDocuments: {
    method: "POST",
    url: `${REACT_MA_API_URL}/GetDocuments`,
  },

  getDocument: {
    method: "POST",
    url: `${REACT_MA_API_URL}/DownloadDocument`,
  },

  GetArchive: {
    method: "POST",
    url: `${REACT_MA_API_URL}/GetArchive`,
  },

  GetAutoPayStatus: {
    method: "POST",
    url: `${REACT_MA_API_URL}/GetAutoPayStatus`,
  },
};

export const idCardEndpoint = {
  getIdCard: {
    method: "POST",
    url: `${REACT_MA_API_URL}/GetIdCards`,
  },
  sendIdCardEmail: {
    method: "POST",
    url: `${REACT_MA_API_URL}/SendIdCardEmail`,
  },
  getVehicleInfo: {
    method: "POST",
    url: `${REACT_MA_API_URL}/GetVehicleInfo`,
  },

  getRenewalQuestionnaires: {
    method: "POST",
    url: `${REACT_MA_API_URL}/GetRenewalQuestionnaires`,
  },

  getRenewalQuestionnairePDF: {
    method: "POST",
    url: `${REACT_MA_API_URL}/getRenewalQuestionnairePDF`,
  },

  getPolicyDocumentsByState: {
    method: "POST",
    url: `${REACT_MA_API_URL}/GetPolicyDocumentsByState`,
  },

  getPolicyDocumentsByDocID: {
    method: "POST",
    url: `${REACT_MA_API_URL}/GetPolicyDocumentsByDocID`,
  },

  getTempIDCard: {
    method: "POST",
    url: `${REACT_MA_API_URL}/DownloadIDCard`,
  },

  sendTempIDCard: {
    method: "POST",
    url: `${REACT_MA_API_URL}/EmailTempIDCard`
  }
};
