import axios from "axios";
import {
  addressChangeEndpoint,
  driverInfoEndpoint,
  oneincEndpoint,
  policyChangeEndpoint,
  portalOne,
  vehicleChangeEndpoint,
  websiteMaintenanceEndpoint
} from "./apiConfig";
import { loginEndpoint, idCardEndpoint, paymentEndpoint } from "./apiConfig";
import { getSiteEnv } from "./Utils";

const Env = "uat";

const domainName = (location) => {
  var name = {
    'chatdev.cure.com': 'Staging',
    'localhost': 'Staging',
    'chatstg.cure.com': 'Staging',
    'chatprep.cure.com': 'Prepublish',
    'chat.cure.com': 'Production',
  }
  return name[location]
}

const headers = {
  "Content-Type": "application/json",
};

const GW_DOMAIN_UAT = "cure-uat.mu-1-us-west-2";
const GW_DOMAIN_PROD = "cure-prod.xi-1-us-west-2";


// #region genesys
// export const getThreadingTimeline = async (Token) => {
//   const { method, url } = genesysEndpoint.getThreadingTimeline
//   const config = {
//     method,
//     url,
//     headers: {
//       "Content-Type": "application/json"
//     },
//     data: {
//       Token: Token
//     }
//   }

//   var apiResponse;
//   try {
//     apiResponse = await axios(config)
//   }
//   catch (e) {
//     console.log(e)
//   }
//   return apiResponse.data
// }

// #endregion

// #region oneinc
export const loadPaymentFromToken = async (Token) => {
  const { method, url } = oneincEndpoint.loadPaymentFromToken
  const config = {
    method,
    url,
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      Token: Token
    }
  }

  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse.data
}

export const getPaymentInfoOneinc = async (PolicyNumber, ZipCode, Env) => {
  const { method, url } = oneincEndpoint.getPaymentInfo
  const config = {
    method,
    url,
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      PolicyNumber: PolicyNumber,
      Zip: ZipCode,
      Env: Env
    }
  }

  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse
}

export const getQuotePaymentInfo = async (QuoteID, ZipCode, Env) => {
  const { method, url } = oneincEndpoint.getQuotePaymentInfo
  const config = {
    method,
    url,
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      QuoteID: QuoteID,
      Zip: ZipCode,
      Env: Env
    }
  }

  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse
}

export const retrieveOneIncSessionID = async (Env) => {
  const { method, url } = oneincEndpoint.retrieveOneIncSessionID
  const config = {
    method,
    url,
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      Env: Env
    }
  }

  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse
}

export const enrollAutoPay = async (Policy, PayMethodTokenId, CustomerID, CustomerName, Email, DueDate, AmountPerInstallment, Env) => {
  const { method, url } = oneincEndpoint.enrollAutoPay
  const config = {
    method,
    url,
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      Policy,
      PayMethodTokenId,
      CustomerID,
      CustomerName,
      Email,
      DueDate,
      AmountPerInstallment,
      Env
    }
  }

  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse.data
}

export const updateAutoPay = async (Policy, PayMethodTokenId, Env) => {
  const { method, url } = oneincEndpoint.updateAutoPay
  const config = {
    method,
    url,
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      Policy,
      PayMethodTokenId,
      Env
    }
  }

  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse.data
}


export const cancelAutoPay = async (Policy, Env) => {
  const { method, url } = oneincEndpoint.cancelAutoPay
  const config = {
    method,
    url,
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      Policy,
      Env
    }
  }

  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse.data
}

export const logDownPayment = async (QuoteNumber, State, ConfirmationNumber, AmountPaid, PaymentDate, Env) => {
  const { method, url } = oneincEndpoint.logDownPayment
  const config = {
    method,
    url,
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      QuoteNumber,
      State,
      ConfirmationNumber,
      AmountPaid,
      PaymentDate,
      Env
    }
  }

  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse.data
}


// #endregion oneinc

// #region my-account

export const getZip = async (PolicyNumber, Env) => {
  const { method, url } = driverInfoEndpoint.getZip

  const config = {
    method,
    url,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      PolicyNumber,
      Env
    }
  }

  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse.data;
};

export const validateAddress = async (fields) => {
  const { method, url } = driverInfoEndpoint.validateAddress

  const config = {
    method,
    url,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      ...fields
    }
  }

  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse;
};

// #endregion

// #region Login
export const processFeedback = async user => {
  const { method, url } = loginEndpoint.feedback
  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ ...user })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getWebsiteMaintenanceStatus = async content => {
  const { method, url } = websiteMaintenanceEndpoint.status
  var Environment = domainName(window.location.hostname)

  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ Environment })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getWebsiteMaintenanceMessage = async content => {
  const { method, url } = websiteMaintenanceEndpoint.message
  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ ...content })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}
export const verifyLoginTkn = async (tkn, userAgent) => {
  const { method, url } = loginEndpoint.verify

  const data = {
    tkn: tkn,
    ua: userAgent
  }

  const config = {
    method,
    url,
    headers: {
      'Content-Type': 'application/json'
    },
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
    console.log("VerifyLogin", apiResponse)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const verifyLogin = async (username, password, userAgent) => {
  const { method, url } = loginEndpoint.verifyUser

  const data = {
    un: username,
    pw: password,
    ua: userAgent
  }

  const config = {
    method,
    url,
    headers: {
      'Content-Type': 'application/json'
    },
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
    console.log("VerifyLogin", apiResponse)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const verifyLoginQS = async (phone_ssn, zipcode) => {
  const { method, url } = loginEndpoint.verifyQS

  const config = {
    method,
    url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      phone_ssn,
      zipcode
    }
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
    console.log("VerifyLoginQS", apiResponse)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const externalLogin = async (token) => {
  const { method, url } = loginEndpoint.externalLogin

  const config = {
    method,
    url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      token
    }
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
    console.log("ExternalLogin", apiResponse)
  } catch (error) {
    console.log("ExternalLogin", error)
  }
  return apiResponse
}

export const AuthenticateODS = async (policyNumber) => {
  const { method, url } = loginEndpoint.authenticateODS;
  const config = {
    method,
    url,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      policyNumber,
    },
  };

  let apiResponse;
  try {
    apiResponse = await axios(config);
    console.log("AuthenticateODS", apiResponse);
  } catch (error) {
    console.log("AuthenticateODS", error);
    return false;
  }
  return apiResponse.data;
};

export const getSecurityQuestions = async () => {
  const { method, url } = loginEndpoint.getSecurityQuestions

  const config = {
    method,
    url,
    headers: {
      'Content-Type': 'application/json'
    }
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    // console.log(error)
  }
  return apiResponse
}

export const registerAccount = async user => {
  const { method, url } = loginEndpoint.registerAccount
  // const data = JSON.stringify(user)
  const config = {
    method,
    url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify({ ...user })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    // console.log(error)
  }
  return apiResponse
}

export const checkSecurityQuestions = async user => {
  const { method, url } = loginEndpoint.checkSecurityQuestions
  // const data = JSON.stringify(user)
  const config = {
    method,
    url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify({ ...user })
  }
  //alert(JSON.stringify(user))

  let apiResponse
  try {
    apiResponse = await axios(config)
    console.log('apiResponse', apiResponse)
  } catch (error) {
    // console.log(error)
    console.log('error', error)
  }
  return apiResponse
}

export const changeSecurityQuestions = async user => {
  const { method, url } = loginEndpoint.changeSecurityQuestions
  const config = {
    method,
    url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify({ ...user })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const retrievePolicyUser = async (LastName, DOB, ZipCode) => {
  const { method, url } = loginEndpoint.retrievePolicyUser
  const config = {
    method,
    url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify({
      LastName,
      DOB,
      ZipCode
    })
  }
  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    // console.log("Try catch >>>>>", error)
  }
  return apiResponse
}

export const resetPasswordWithTemp = async (user) => {
  const { method, url } = loginEndpoint.resetPassword
  const config = {
    method,
    url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify({ ...user })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    // console.log(error)
  }
  return apiResponse
}

export const updateEmail = async (user) => {
  const { method, url } = loginEndpoint.updateEmail
  const config = {
    method,
    url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: user
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const setPaperlessEmail = async (user) => {
  const { method, url } = loginEndpoint.setPaperlessEmail
  const config = {
    method,
    url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: user
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const updateUsername = async (user) => {
  const { method, url } = loginEndpoint.updateUsername
  const config = {
    method,
    url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: user
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const updatePassword = async (user) => {
  const { method, url } = loginEndpoint.updatePassword
  const config = {
    method,
    url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify({ ...user })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const registerForBilling = async (user) => {
  const { method, url, ...props } = paymentEndpoint.registerForBilling
  // const Env = await getEnv()

  const config = {
    method,
    url,
    headers,
    data: {
      ...user,
      ...props,
    },
  };

  var apiResponse;
  try {
    apiResponse = await axios(config);
    console.log("RegisterForBilling", apiResponse);
  } catch (error) {
    console.error("RegisterForBilling", error);
    return { error };
  }
  return apiResponse.data;
};

export const loadByPolicyNumber = async (policyNumber, env) => {
  const { method, url, ...props } = loginEndpoint.loadByPolicyNumber
  // const Env = await getEnv()

  const config = {
    method,
    url,
    headers,
    data: {
      policyNumber,
      env,
      ...props,
    },
  };

  var apiResponse;
  try {
    apiResponse = await axios(config);
    console.log("LoadByPolicyNumber", apiResponse);
  } catch (error) {
    console.error("LoadByPolicyNumber", error);
    return { error };
  }
  return apiResponse;
};
//#endregion

// #region Payment

export const getPaymentInfo = async (policyNumber, systemID, env) => {
  const { method, url, ...props } = paymentEndpoint.getPaymentInfo
  // const Env = await getEnv()

  const config = {
    method,
    url,
    headers,
    data: {
      policyNumber,
      systemID,
      env,
      ...props,
    },
  };
  var apiResponse;
  try {
    apiResponse = await axios(config);

    console.log("GetPaymentInfo", apiResponse);
  } catch (error) {
    console.error("GetPaymentInfo", error);
    return { error };
  }
  return apiResponse.data;
};

export const getLastPayment = async (policyNumber) => {
  const { method, url, ...props } = paymentEndpoint.getLastPayment;
  // const Env = await getEnv()

  const config = {
    method,
    url,
    headers,
    data: {
      sqlparams: { "{{PolicyNumber}}": policyNumber },
      Env,
      ...props,
    },
  };

  var apiResponse;
  try {
    apiResponse = await axios(config);
    apiResponse.data = JSON.parse(apiResponse.data);
    console.log("GetLastPayment", apiResponse);
  } catch (error) {
    console.error("GetLastPayment", error);
    return { error };
  }
  return apiResponse.data.result;
};

export const getPaymentHistory = async (policyNumber, env) => {
  const { method, url, ...props } = paymentEndpoint.getPaymentHistory

  const config = {
    method,
    url,
    headers,
    data: {
      policyNumber,
      env,
      ...props,
    },
  };

  var apiResponse;
  try {
    apiResponse = await axios(config);
    console.log("GetPaymentHistory", apiResponse);
  } catch (error) {
    console.error("GetPaymentHistory", error);
    return { error };
  }
  return apiResponse.data;
};

export const getPaperlessBillHistory = async (accountRef, systemId, env) => {
  const { method, url, ...props } = paymentEndpoint.getPaperlessBillHistory;

  const config = {
    method,
    url,
    headers,
    data: {
      sqlparams: {
        "{{AccountRef}}": accountRef,
        "{{SystemId}}": systemId,
      },
      env,
      ...props,
    },
  };

  var apiResponse;
  try {
    apiResponse = await axios(config);
    apiResponse.data = JSON.parse(apiResponse.data);
    console.log("GetPaymentHistory", apiResponse);
  } catch (error) {
    console.error("GetPaymentHistory", error);
    return { error };
  }
  return apiResponse.data.result;
};

export const getInstallmentBill = async (policyNumber, systemId, env) => {
  const { method, url, ...props } = paymentEndpoint.getInstallmentBill;

  const config = {
    method,
    url,
    headers,
    data: {
      policyNumber,
      systemId,
      env,
      ...props,
    },
  };

  var apiResponse;
  try {
    apiResponse = await axios(config);
    console.log("GetInstallmentBill", apiResponse);
  } catch (error) {
    console.error("GetInstallmentBill", error);
    return { error };
  }
  return apiResponse.data;
};

export const getPaymentPdf = async (systemId, id, env) => {
  const { method, url, endpoint, ...props } = paymentEndpoint.getPaymentPdf;

  const endpointUpdated = endpoint
    .replace("{{systemId}}", systemId)
    .replace("{{id}}", id)
    .replace("{{GW_DOMAIN}}", env === "uat" ? GW_DOMAIN_UAT : GW_DOMAIN_PROD);

  const config = {
    method,
    url,
    headers,
    data: {
      endpoint: endpointUpdated,
      env,
      ...props,
    },
  };

  let apiResponse;
  try {
    apiResponse = await axios(config);
    console.log("getPaymentPdf", apiResponse);
  } catch (error) {
    console.error("getPaymentPdf", error);
    return { error };
  }
  return apiResponse.data;
};

export const getAllPolicyDocs = async (systemId) => {
  const { method, url, ...props } = paymentEndpoint.getAllPolicyDocs;
  // const Env = await getEnv()

  const config = {
    method,
    url,
    headers,
    data: {
      sqlparams: { "{{systemId}}": systemId },
      Env,
      ...props
    }
  }

  var apiResponse
  try {
    apiResponse = await axios(config)
    apiResponse.data = JSON.parse(apiResponse.data)
    console.log("getAllPolicyDocs", apiResponse)
  } catch (error) {
    console.error("getAllPolicyDocs", error)
    return { error }
  }
  return apiResponse.data.result
}

export const GetArchive = async (policyNumber, systemId, accountRef, env) => {
  const { method, url, ...props } = paymentEndpoint.GetArchive;

  const config = {
    method,
    url,
    headers,
    data: {
      policyNumber,
      systemId,
      accountRef,
      env,
      ...props
    }
  }

  var apiResponse
  try {
    apiResponse = await axios(config)
    console.log("GetArchive", apiResponse)
  } catch (error) {
    console.error("GetArchive", error)
    return { error }
  }
  return apiResponse.data
}
export const getDocuments = async (policyNumber, systemId, env) => {
  const { method, url, ...props } = paymentEndpoint.getDocuments;

  const config = {
    method,
    url,
    headers,
    data: {
      policyNumber,
      systemId,
      env,
      ...props
    }
  }

  var apiResponse
  try {
    apiResponse = await axios(config)
    console.log("GetDocuments", apiResponse)
  } catch (error) {
    console.error("GetDocuments", error)
    return { error }
  }
  return apiResponse.data
}

export const getDocument = async (endpoint, policyNumber, env) => {
  const { method, url, ...props } = paymentEndpoint.getDocument;

  const config = {
    method,
    url,
    headers,
    data: {
      endpoint,
      policyNumber,
      env,
      ...props
    }
  }

  var apiResponse
  try {
    apiResponse = await axios(config)
    console.log("GetDocument", apiResponse)
  } catch (error) {
    console.error("GetDocument", error)
    return { error }
  }
  return apiResponse.data
}

export const getAutoPayStatus = async (policyNumber, env) => {
  const { method, url, ...props } = paymentEndpoint.GetAutoPayStatus;

  const config = {
    method,
    url,
    headers,
    data: {
      policyNumber,
      env,
      ...props
    }
  }

  var apiResponse
  try {
    apiResponse = await axios(config)
    // console.log("GetAutoPayStatus", apiResponse)
  } catch (error) {
    // console.error("GetAutoPayStatus", error)
    return { error }
  }
  return apiResponse.data
}

//#endregion

// #region Id Card

export const getVehicleInfo = async (policyNumber, systemId, env) => {
  const { method, url, ...props } = idCardEndpoint.getVehicleInfo;

  const config = {
    method,
    url,
    headers,
    data: {
      policyNumber,
      systemId,
      env,
      ...props,
    },
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("GetVehicleInfo", apiResponse);
  } catch (error) {
    console.error("GetVehicleInfo", error);
    return { error };
  }
  return apiResponse.data;
};

export const getRenewalQuestionnaires = async (policyNumber) => {
  const { method, url, ...props } = idCardEndpoint.getRenewalQuestionnaires;

  const config = {
    method,
    url,
    headers,
    data: {
      policyNumber,
      ...props,
    },
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("GetRenewalQuestionnaires", apiResponse);
  } catch (error) {
    console.error("GetRenewalQuestionnaires", error);
    return { error };
  }
  return apiResponse.data;
};

export const getRenewalQuestionnairePDF = async (req) => {
  const { method, url, ...props } = idCardEndpoint.getRenewalQuestionnairePDF;

  const config = {
    method,
    url,
    headers,
    data: {
      ...req,
      ...props,
    },
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("GetRenewalQuestionnaires", apiResponse);
  } catch (error) {
    console.error("GetRenewalQuestionnaires", error);
    return { error };
  }
  return apiResponse.data;
};

export const getPolicyDocumentsByState = async (state) => {
  const { method, url, ...props } = idCardEndpoint.getPolicyDocumentsByState;

  const config = {
    method,
    url,
    headers,
    data: {
      state,
      ...props,
    },
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("GetPolicyDocumentsByState", apiResponse);
  } catch (error) {
    console.error("GetPolicyDocumentsByState", error);
    return { error };
  }
  return apiResponse.data;
}

export const getPolicyDocumentsByDocID = async (docID) => {
  const { method, url, ...props } = idCardEndpoint.getPolicyDocumentsByDocID;

  const config = {
    method,
    url,
    headers,
    data: {
      docID,
      ...props,
    },
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("GetPolicyDocumentsByState", apiResponse);
  } catch (error) {
    console.error("GetPolicyDocumentsByState", error);
    return { error };
  }
  return apiResponse.data;
};

export const getIdCards = async (systemId, policyNumber, idList, env) => {
  const { method, url, ...props } = idCardEndpoint.getIdCard;

  const config = {
    method,
    url,
    headers,
    data: {
      systemId,
      policyNumber,
      idList,
      env,
      ...props,
    },
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("GetIdCard", apiResponse);
  } catch (error) {
    console.error("GetIdCard", error);
    return { error };
  }
  return apiResponse.data;
};

export const sendIdCardEmail = async (systemId, policyNumber, idList, emailAddress, bodyParams, env) => {
  const { method, url, ...props } = idCardEndpoint.sendIdCardEmail;

  const config = {
    method,
    url,
    headers,
    data: {
      systemId,
      emailAddress,
      policyNumber,
      idList,
      bodyParams,
      env,
      ...props,
    },
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("SendIdCardEmail", apiResponse);
  } catch (error) {
    console.error("SendIdCardEmail", error);
    return { error };
  }
  return apiResponse.data;
};
//#endregion

// #region Portal One

export const getCustomerId = async (ExternalCustomerId, CustomerName, env) => {
  const { method, url, ...props } = portalOne.getCustomerId;

  const config = {
    method,
    url: url(env),
    headers,
    data: {
      ExternalCustomerId,
      CustomerName,
      PortalOneAuthenticationKey: portalOne.portalOneAuthKey(env),
      ...props,
    },
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("GetCustomerId", apiResponse);
  } catch (error) {
    console.error("GetCustomerId", error);
    return { ResponseCode: error };
  }
  return apiResponse.data;
};

export const getPaymentMethod = async (CustomerId, env) => {
  const { method, url, ...props } = portalOne.getPaymentMethod;

  const config = {
    method,
    url: url(env),
    headers,
    data: {
      CustomerId: CustomerId,
      PortalOneAuthenticationKey: portalOne.portalOneAuthKey(env),
      ...props,
    },
  };
  var apiResponse;

  // console.log(config);
  try {
    apiResponse = await axios(config);
    // console.log("GetPaymentMethod", apiResponse);
  } catch (error) {
    console.error("GetPaymentMethod", error);
    return { ResponseCode: error };
  }
  return apiResponse.data;
};

export const checkAutoPay = async (TokenId, env) => {
  const { method, url, ...props } = portalOne.checkAutoPay;

  const config = {
    method,
    url: url(env),
    headers,
    data: {
      AuthenticationKey: portalOne.authKey(env),
      TokenId,
      ...props,
    },
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("CheckAutoPay", apiResponse);
  } catch (error) {
    console.error("CheckAutoPay", error);
    return { ResponseCode: error };
  }
  return apiResponse.data;
};

export const getActiveInstallmentPlan = async (PolicyRefNumber, env) => {
  const { method, url, ...props } = portalOne.getActiveInstallmentPlan;

  const config = {
    method,
    url: url(env),
    headers,
    data: {
      AuthenticationKey: portalOne.authKey(env),
      PolicyRefNumber,
      ...props,
    },
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    // console.log("GetActiveInstallmentPlan", apiResponse);
  } catch (error) {
    console.error("GetActiveInstallmentPlan", error);
    return { ResponseCode: error };
  }
  return apiResponse.data;
};

export const checkPBTStatus = async (PolicyRefNumber, Env) => {
  const { method, url, ...props } = portalOne.checkPBTStatus;

  const data = JSON.stringify({
    PortalOneAuthenticationKey: portalOne.portalOneAuthKey(Env),
    ExternalCustomerIdList: [PolicyRefNumber],
    Env,
    ...props,
  })

  const config = {
    method,
    url,
    headers,
    data,
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("CheckPBTStatus", apiResponse);
  } catch (error) {
    console.error("CheckPBTStatus", error);
    return { ResponseCode: error };
  }
  return apiResponse.data;
};

export const downloadTempIDCard = async (changeId, requestType) => {
  const { method, url } = idCardEndpoint.getTempIDCard;

  var data = {
    changeId,
    requestType
  }

  const config = {
    method,
    url,
    headers,
    data,
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("DownloadIDCard", apiResponse);
  } catch (error) {
    console.error("DownloadIDCard", error);
    return { ResponseCode: error };
  }
  return apiResponse.data;
};

export const emailTempIDCard = async (ChangeID, requestType, UserState, EmailAddress, FirstName) => {
  const { method, url } = idCardEndpoint.sendTempIDCard;

  var data = {
    ChangeID,
    requestType,
    UserState,
    EmailAddress,
    FirstName
  }

  const config = {
    method,
    url,
    headers,
    data,
  };
  var apiResponse;

  try {
    apiResponse = await axios(config);
    console.log("EmailTempIDCard", apiResponse);
  } catch (error) {
    console.error("EmailTempIDCard", error);
    return { ResponseCode: error };
  }
  return apiResponse.data;
};
// #endregion
